<template>
  <h5 class="list-item grey--text">
    <span>
      <i v-if="icon" :class="icon"></i>
      {{ title }}
    </span>
    <button class="link" @click="$emit('edit-section')">
      {{ buttonText }}
    </button>
  </h5>
</template>

<script>
export default {
  name: "EditSectionHeading",

  props: {
    title: { type: String, required: true },
    buttonText: { type: String, default: "Edit" },
    icon: String
  }
};
</script>
