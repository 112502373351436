<template>
  <!-- Personal Information Column -->
  <div class="list" v-if="activeUser">
    <edit-section-heading
      title="Personal Information"
      icon="fas fa-user-circle accent--text"
      @edit-section="$emit('start-editing', 'Personal Info')"
    />

    <div class="list-item">
      <b class="h6 label">Name:</b>
      <hr class="divider divider--vertical" />
      {{ activeUser.fullName }}
    </div>

    <div class="list-item" v-if="activeUser.proNouns">
      <b class="h6 label">Prefers:</b>
      <hr class="divider divider--vertical" />
      {{ activeUser.proNouns }}
    </div>

    <div class="list-item">
      <b class="h6 label">Country:</b>
      <hr class="divider divider--vertical" />
      {{ activeUser.country }}
    </div>

    <div class="list-item">
      <b class="h6 label">Time zone:</b>
      <hr class="divider divider--vertical" />
      {{ findTZByText(activeUser.timeZone).name }}
    </div>

    <!-- Notifications -->
    <edit-section-heading
      title="Notifications"
      icon="fas fa-bell-on accent--text"
      @edit-section="$emit('start-editing', 'Notifications')"
    />

    <div
      class="list-item"
      v-for="(optedIn, type) in {
        email: activeUser.emailOptIn,
        mobile: activeUser.mobileOptIn
      }"
      :key="type"
    >
      <i
        :class="{
          'fas fa-check-circle success--text': optedIn,
          'fas fa-times-circle error--text': !optedIn
        }"
      />
      <hr class="divider divider--vertical" />
      {{ optedIn ? "Send" : "Do not send" }} me
      {{ type }}
      notifications
    </div>

    <hr class="divider" />
  </div>
</template>

<script>
import { findTZByText } from "../models/zoom";
import EditSectionHeading from "./EditSectionHeading.vue";
import PermissionsMixin from "./mixins/permissions.mixin";

export default {
  name: "UserSettings",

  components: { EditSectionHeading },

  mixins: [PermissionsMixin],

  data: () => ({
    activeSection: null,
    editUserSection: false,
    loading: true,
    unsubscribe: null,
    user: null
  }),

  async mounted() {
    this.attachToState();
    this.loading = false;
  },

  methods: {
    findTZByText
  }
};
</script>
