<template>
  <loader v-if="loading" />

  <!-- Admin/Super Admin edit user -->
  <user-form
    v-else-if="$route.name === 'EditSettings'"
    :form-data="activeUser"
    disable-role
  />

  <!-- Edit Active User -->
  <section v-else id="user-settings">
    <!-- Profile Pic -->
    <user-profile-image
      v-if="activeUser"
      :employee="activeUser.employee"
      :image-src="activeUser.userImage"
      :image-alt="`${activeUser.firstName}'s profile picture`"
      :selectable="!isStudentUser"
      :subscriber="activeUser.stripeSubActive"
      @image-click="!isStudentUser && toggleUserSection('User Bio')"
    />

    <button class="error logout" @click="logout">Logout</button>

    <div v-if="activeUser" class="content columns">
      <!-- Personal Information + Notifications Column -->
      <user-settings-personal-info @start-editing="toggleUserSection" />

      <!-- Account info + User Bio Column -->
      <user-settings-account-info @start-editing="toggleUserSection" />
    </div>

    <modal-component
      :title="`Edit ${activeSection}`"
      :visible="editUserSection"
      @close="editUserSection = false"
    >
      <user-form
        v-if="editUserSection"
        :form-data="activeUser"
        disable-role
        :section="activeSection"
        @updated="toggleUserSection"
      />
    </modal-component>
  </section>
</template>

<script>
import { manageSubscription } from "../models/stripe";
import Loader from "./Loader.vue";
import ModalComponent from "./ModalComponent.vue";
import UserForm from "./UserForm.vue";
import PermissionsMixin from "./mixins/permissions.mixin";
import { USER_PROFILE_SECTIONS } from "../models/permissions";
import UserSettingsAccountInfo from "./UserSettings.AccountInfo.vue";
import UserSettingsPersonalInfo from "./UserSettings.PersonalInfo.vue";
import UserProfileImage from "./UserProfileImage.vue";

export default {
  name: "UserSettings",

  components: {
    Loader,
    ModalComponent,
    UserForm,
    UserSettingsAccountInfo,
    UserSettingsPersonalInfo,
    UserProfileImage
  },

  mixins: [PermissionsMixin],

  data: () => ({
    activeSection: null,
    editUserSection: false,
    membershipTrialLength: 7,
    unsubscribe: null,
    user: null
  }),

  async mounted() {
    this.attachToState();
    this.loading = false;
  },

  methods: {
    async manageSubscription() {
      try {
        const { stripeAcctId: customerId } = this.activeUser;
        await manageSubscription(customerId);
      } catch (error) {
        console.warn(error);
      }
    },

    toggleUserSection(section = null) {
      this.editUserSection = USER_PROFILE_SECTIONS.includes(section);
      this.activeSection = this.editUserSection ? section : null;
    }
  }
};
</script>

<style lang="scss" src="./UserSettings.scss"></style>
