<template>
  <!-- Account info Column -->
  <p v-if="!activeUser">Loading user...</p>

  <div class="list list--account-info" v-else>
    <loader v-if="loading" :loading-message="loadingMessage" />

    <edit-section-heading
      title="Account Info"
      icon="fas fa-address-card accent--text"
      @edit-section="$emit('start-editing', 'Account Info')"
    />

    <div class="list-item">
      <b class="h6 label">Username:</b>
      <hr class="divider divider--vertical" />
      {{ activeUser.username }}
    </div>

    <div class="list-item">
      <b class="h6 label">Email:</b>
      <hr class="divider divider--vertical" />
      {{ activeUser.email }}
    </div>

    <div class="list-item">
      <b class="h6 label">Role:</b>
      <hr class="divider divider--vertical" />
      {{ activeUser.role }}
    </div>

    <!-- User Bio (Practitioners and Teachers only) -->
    <edit-section-heading
      v-if="isTeacherUser"
      :title="`${activeUser.role} Bio`"
      icon="fas fa-address-card accent--text"
      @edit-section="$emit('start-editing', 'User Bio')"
    />

    <div
      class="list-item user-bio"
      v-if="isTeacherUser"
      v-html="activeUser.description"
    />

    <!-- (Stripe) Subscriptions -->
    <h5 class="list-item grey--text">
      <i class="fas fa-credit-card accent--text"></i>
      <span class="list-item" style="border: 0">Subscriptions</span>
    </h5>

    <!-- Monthly/Annual Membership Subscription Active -->
    <div class="list-item" v-if="activeUser.stripeSubActive">
      <span class="grow">
        <i class="fas fa-check-circle success--text"></i>
        &nbsp;
        <b>Subscription active.</b>
      </span>
      <button class="outline" @click="manageSubscription">
        Manage
        <i class="fas fa-external-link"></i>
      </button>
    </div>

    <!-- Trial/Limited-period Membership Active -->
    <div class="list-item" v-if="trialMembershipActive">
      <span class="grow">
        <i class="fas fa-check-circle success--text"></i>
        &nbsp;
        <b>Membership active.</b>
      </span>
      <span class="no-shrink" style="font-size: 0.7rem">
        <b>Expires</b> {{ trialMembershipRelativeEnd }}
      </span>
    </div>

    <button
      v-if="trialMembershipEligible"
      class="outline success--text"
      @click.stop="showMembershipTrial = true"
    >
      Start Membership Trial!
    </button>

    <template
      v-if="!activeUser.trialMembershipActive && !activeUser.stripeSubActive"
    >
      <em class="list-item list-item--label">
        You don't have an active subscription
      </em>
      <hr class="divider" />
    </template>

    <modal-component
      :title="`${membershipTrialLength}-Day Membership Trial`"
      :visible="showMembershipTrial"
      @close="showMembershipTrial = false"
    >
      <limited-membership-trial
        @start-membership-trial="beginTrialMembership"
      />
    </modal-component>
  </div>
</template>

<script>
import { manageSubscription } from "../models/stripe";
import DateTimeMixin from "./mixins/date-time.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";
import EditSectionHeading from "./EditSectionHeading.vue";
import ModalComponent from "./ModalComponent.vue";
import Loader from "./Loader.vue";
import LimitedMembershipTrial from "./LimitedMembershipTrial.vue";
import { startLimitedMembership } from "../models/user";
import state from "../state";

export default {
  name: "UserSettingsAccountInfo",

  components: {
    EditSectionHeading,
    LimitedMembershipTrial,
    ModalComponent,
    Loader
  },

  mixins: [DateTimeMixin, PermissionsMixin],

  data: () => ({
    activeSection: null,
    editUserSection: false,
    loading: true,
    membershipTrialLength: 7,
    showMembershipTrial: false,
    unsubscribe: null,
    user: null
  }),

  async mounted() {
    this.attachToState();
    this.loading = false;
  },

  methods: {
    async manageSubscription() {
      try {
        const { stripeAcctId: customerId } = this.activeUser;
        await manageSubscription(customerId);
      } catch (error) {
        console.warn(error);
      }
    },

    async beginTrialMembership() {
      this.startLoading("Activating Trial Membership...");

      this.showMembershipTrial = false;
      const days = this.membershipTrialLength + 1;
      const user = await startLimitedMembership(this.activeUser, { days });
      state.user(user);
      this.stopLoading();
    }
  }
};
</script>

<style lang="scss">
.list--account-info {
  padding-bottom: $md;
  padding-right: 0;

  .user-bio {
    @include flex-column;
    font-size: $xs;
    padding: $sm $xs;

    p {
      margin-bottom: $xxxs;
    }
  }
}
</style>
