<template>
  <section id="limited-membership-trial">
    <branded-divider />
    <p>
      Join the <b>{{ appOrgName }}</b> family! Enjoy full membership benefits
      with a <b>risk-free {{ trialLengthDays }}-day trial</b>. No credit card
      required!
    </p>

    <hr class="divider divider--tiny" />

    <p>
      Your trial will automatically end
      <b>
        {{ `${trialLengthDays} ${trialLengthDays === 1 ? "day" : "days"}` }}
      </b>
      after you begin. You can sign up for a full membership at any time after
      it ends.
    </p>

    <hr class="divider divider--tiny" />

    <p>Interested? Just hit the button below to begin!</p>

    <branded-divider />
    <button class="success wide" @click.stop="$emit('start-membership-trial')">
      Start Membership Trial!
    </button>
  </section>
</template>

<script>
import BrandedDivider from "./BrandedDivider.vue";
import PermissionsMixin from "./mixins/permissions.mixin";

export default {
  name: "LimitedMembershipTrial",

  mixins: [PermissionsMixin],

  components: { BrandedDivider },

  props: { trialLengthDays: { type: Number, default: 7 } }
};
</script>
